var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Home"},[_c('img',{attrs:{"alt":"Vue logo","src":require("../assets/curiouscargo.jpg"),"height":"200px"}}),_c('br'),_c('label',{attrs:{"for":"difficulty"}},[_vm._v("Difficulty (0-4)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.difficulty),expression:"difficulty"}],attrs:{"type":"number","name":"difficulty","id":"difficulty","min":"0","max":"4","enabled":_vm.gameInProgress},domProps:{"value":(_vm.difficulty)},on:{"change":_vm.checkDifficulty,"focus":_vm.checkDifficulty,"focusout":_vm.checkDifficulty,"input":function($event){if($event.target.composing)return;_vm.difficulty=$event.target.value}}}),_c('br'),_c('button',{on:{"click":function($event){_vm.cardSequence = []}}},[_vm._v("Reset")]),_c('button',{on:{"click":_vm.setCardSequence}},[_vm._v("Build Deck")]),_c('button',{on:{"click":_vm.incrementCardCounter}},[_vm._v("Next Card")]),_c('button',{on:{"click":_vm.decrementCardCounter}},[_vm._v("Previous Card")]),(_vm.cardSequence.length > 0)?_c('h1',[_vm._v(" "+_vm._s(_vm.cardCounter + 1)+"/"+_vm._s(_vm.cardSequence.length + parseInt(_vm.difficulty))+" ")]):_vm._e(),(_vm.cardSequence.length > 0)?_c('Card',{attrs:{"item":_vm.cardCounter >= parseInt(_vm.difficulty) &&
			_vm.cardCounter <= _vm.cardSequence.length + parseInt(_vm.difficulty) - 1
				? _vm.deck[_vm.cardSequence[_vm.cardCounter - parseInt(_vm.difficulty)]]
				: _vm.cardCounter <= parseInt(_vm.difficulty)
				? {
						index: `C${_vm.cardCounter}`,
						Title: 'Countdown',
						Text: parseInt(_vm.difficulty) - _vm.cardCounter,
				  }
				: {
						index: `fin`,
						Title: 'Game Over',
						Text: 'The card deck is empty',
				  }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }