<template>
	<div class="Card">
		<h1 id="title">{{ item.Title }}</h1>
		<h2 id="location" v-if="item.Location != ''">{{ item.Location }}</h2>
		<h3 id="text">{{ item.Text }}</h3>
		<h3 id="orText" v-if="item.Or != ''">{{ item.Or }}</h3>
		<h4 id="index">{{ item.index }}</h4>
	</div>
</template>

<script>
export default {
	name: "cc-card",
	props: {
		item: Object,
	},
};
</script>

<style lang="scss" scoped></style>
