<template>
	<div class="Home">
		<img alt="Vue logo" src="../assets/curiouscargo.jpg" height="200px" /><br />
		<!-- <h1>{{ cardSequence }}</h1> -->
		<!-- <h1>{{ cardCounter }}</h1> -->
		<label for="difficulty">Difficulty (0-4)</label>
		<input
			type="number"
			name="difficulty"
			id="difficulty"
			min="0"
			max="4"
			v-model="difficulty"
			@change="checkDifficulty"
			@focus="checkDifficulty"
			@focusout="checkDifficulty"
			:enabled="gameInProgress"
		/>
		<br />
		<button @click="cardSequence = []">Reset</button>
		<button @click="setCardSequence">Build Deck</button>
		<button @click="incrementCardCounter">Next Card</button>
		<button @click="decrementCardCounter">Previous Card</button>
		<h1 v-if="cardSequence.length > 0">
			{{ cardCounter + 1 }}/{{ cardSequence.length + parseInt(difficulty) }}
		</h1>

		<Card
			v-if="cardSequence.length > 0"
			:item="
				cardCounter >= parseInt(difficulty) &&
				cardCounter <= cardSequence.length + parseInt(difficulty) - 1
					? deck[cardSequence[cardCounter - parseInt(difficulty)]]
					: cardCounter <= parseInt(difficulty)
					? {
							index: `C${cardCounter}`,
							Title: 'Countdown',
							Text: parseInt(difficulty) - cardCounter,
					  }
					: {
							index: `fin`,
							Title: 'Game Over',
							Text: 'The card deck is empty',
					  }
			"
		/>
	</div>
</template>

<script>
// @ is an alias to /src
import Card from "@/components/Card.vue";

export default {
	name: "Home",
	components: {
		Card,
	},
	methods: {
		setCardSequence() {
			this.cardCounter = 0;
			var numbers = [
				0,
				1,
				2,
				3,
				4,
				5,
				6,
				7,
				8,
				9,
				10,
				11,
				12,
				13,
				14,
				15,
				16,
				17,
				18,
				19,
				20,
				21,
				22,
				23,
				24,
				25,
				26,
				27,
			];
			var sequence = [];
			var i = numbers.length;
			var generatedNumber = 0;
			while (i--) {
				generatedNumber = Math.floor(Math.random() * (i + 1));
				sequence.push(numbers[generatedNumber]);
				numbers.splice(generatedNumber, 1);
			}
			this.cardSequence = sequence;
			this.gameinProgress = true;
			return sequence;
		},
		incrementCardCounter() {
			if (
				this.cardCounter <=
				this.cardSequence.length + (parseInt(this.difficulty) - 1)
			) {
				this.cardCounter++;
			}
		},
		decrementCardCounter() {
			if (this.cardCounter > 0) {
				this.cardCounter--;
			}
		},
		checkDifficulty() {
			if (parseInt(this.difficulty) > 4) {
				this.difficulty = 4;
			} else if (parseInt(this.difficulty) < 0) {
				this.difficulty = 0;
			}
		},
		resetAll() {
			this.cardCounter = 0;
			this.cardSequence = [];
			this.difficulty = 4;
			this.gameinProgress = false;
		},
	},
	data() {
		return {
			deck: [
				{
					index: 0,
					Title: "Building",
					Text: "Place a tile into storage.",
				},
				{
					index: 1,
					Title: "Building",
					Text: "Place a tile into storage.",
				},
				{
					index: 2,
					Title: "Building",
					Text: "Place a tile into storage.",
				},
				{
					index: 3,
					Title: "Building",
					Text: "Place a tile into storage.",
				},
				{
					index: 4,
					Title: "Shipping",
					Location: "2",
					Text: "Avail or Occu +2 --> Move cube place.",
					Or: "Occu --> Move up to next avail",
				},
				{
					index: 5,
					Title: "Shipping",
					Location: "3",
					Text: "Avail or Occu +2 --> Move cube place.",
					Or: "Occu --> Move up to next avail",
				},
				{
					index: 6,
					Title: "Shipping",
					Location: "4",
					Text: "Avail or Occu +2 --> Move cube place.",
					Or: "Occu --> Move up to next avail",
				},
				{
					index: 7,
					Title: "Shipping",
					Location: "5",
					Text: "Avail or Occu +2 --> Move cube place.",
					Or: "Occu --> Move up to next avail",
				},
				{
					index: 8,
					Title: "Shipping",
					Location: "6",
					Text: "Avail or Occu +2 --> Move cube place.",
					Or: "Occu --> Move down to next avail",
				},
				{
					index: 9,
					Title: "Shipping",
					Location: "7",
					Text: "Avail or Occu +2 --> Move cube place.",
					Or: "Occu --> Move down to next avail",
				},
				{
					index: 10,
					Title: "Shipping",
					Location: "8",
					Text: "Avail or Occu +2 --> Move cube place.",
					Or: "Occu --> Move down to next avail",
				},
				{
					index: 11,
					Title: "Shipping",
					Location: "9",
					Text: "Avail or Occu +2 --> Move cube place.",
					Or: "Occu --> Move down to next avail",
				},
				{
					index: 12,
					Title: "Shipping",
					Location: "Lowest",
					Text: "Remove lowest matched line.",
					Or: "Move cube.  Place lowest avail.",
				},
				{
					index: 13,
					Title: "Shipping",
					Location: "Lowest",
					Text: "Remove lowest matched line.",
					Or: "Move cube.  Place lowest avail.",
				},
				{
					index: 14,
					Title: "Shipping",
					Location: "Lowest",
					Text: "Remove lowest matched line.",
					Or: "Move cube.  Place lowest avail.",
				},
				{
					index: 15,
					Title: "Shipping",
					Location: "Lowest",
					Text: "Remove lowest matched line.",
					Or: "Move cube.  Place lowest avail.",
				},
				{
					index: 16,
					Title: "Receiving/Shipping",
					Location: "2",
					Text: "Opp active line --> Place matching.",
					Or: "No opp line --> Move cube.  Place Shipping.",
				},
				{
					index: 17,
					Title: "Receiving/Shipping",
					Location: "3",
					Text: "Opp active line --> Place matching.",
					Or: "No opp line --> Move cube.  Place Shipping.",
				},
				{
					index: 18,
					Title: "Receiving/Shipping",
					Location: "4",
					Text: "Opp active line --> Place matching.",
					Or: "No opp line --> Move cube.  Place Shipping.",
				},
				{
					index: 19,
					Title: "Receiving/Shipping",
					Location: "5",
					Text: "Opp active line --> Place matching.",
					Or: "No opp line --> Move cube.  Place Shipping.",
				},
				{
					index: 20,
					Title: "Receiving/Shipping",
					Location: "6",
					Text: "Opp active line --> Place matching.",
					Or: "No opp line --> Move cube.  Place Shipping.",
				},
				{
					index: 21,
					Title: "Receiving/Shipping",
					Location: "7",
					Text: "Opp active line --> Place matching.",
					Or: "No opp line --> Move cube.  Place Shipping.",
				},
				{
					index: 22,
					Title: "Receiving/Shipping",
					Location: "8",
					Text: "Opp active line --> Place matching.",
					Or: "No opp line --> Move cube.  Place Shipping.",
				},
				{
					index: 23,
					Title: "Receiving/Shipping",
					Location: "9",
					Text: "Opp active line --> Place matching.",
					Or: "No opp line --> Move cube.  Place Shipping.",
				},
				{
					index: 24,
					Title: "Receiving",
					Location: "Lowest",
					Text: "Match opp lowest.",
					Or: "None unmatched --> Place lowest avail.",
				},
				{
					index: 25,
					Title: "Receiving",
					Location: "Lowest",
					Text: "Match opp lowest.",
					Or: "None unmatched --> Place lowest avail.",
				},
				{
					index: 26,
					Title: "Receiving",
					Location: "Lowest",
					Text: "Match opp lowest.",
					Or: "None unmatched --> Place lowest avail.",
				},
				{
					index: 27,
					Title: "Receiving",
					Location: "Lowest",
					Text: "Match opp lowest.",
					Or: "None unmatched --> Place lowest avail.",
				},
			],
			cardCounter: 0,
			cardSequence: [],
			difficulty: 4,
			gameInProgress: false,
		};
	},
};
</script>
